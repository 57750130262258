


const deviceStorage = {
    saveItem(key: string, value: any) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getItem(key: string) {
      var item = localStorage.getItem(key);
      if(item === null || item === undefined) {
        return null;
      }
      return JSON.parse(item);
    },
    removeItem(key: string) {
      return localStorage.removeItem(key);
  }
};

export default deviceStorage;