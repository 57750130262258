import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import './fonts/Allura/Allura-Regular.ttf';

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#345a7f',
    },
    secondary: {
      main: '#d4c2b1',
    },
    background: {
      default: "#f4f5f6"
    },
    /*
    success: {
      main: '#7fbd5a',
    },
    */

    //error: {
     // main: '#db565b',
    //},
  },
  typography: {
    caption: {
      fontSize: "0.8rem"
    },
    fontSize: 16,
    fontFamily: "GlacialIndifferenceRegular"
  },
  shape: {
    borderRadius: 12
  }
};

//Dark blue #25405a

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
