import { addMinutes } from "../common/utils";

export default class CachedValue<T> {
    value: T;
    expireDate: Date;
    refresh: boolean;
    /**
     * Whether the value is from cache or direct from the server.
     */
    isFromCache: boolean;

    constructor(value: T, isFromCache: boolean, expiresIn: number = 30, refresh: boolean) {
        this.value = value;
        this.isFromCache = isFromCache;
        this.refresh = refresh;

        this.expireDate = addMinutes(new Date(), expiresIn);
    }
}