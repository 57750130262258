import CachedValue from "../models/CachedValue";
import { getCurrentDateTimeUTC } from "../common/utils";
import deviceStorage from "./deviceStorage";

const cachedStorage = {
    async getValue<T>(key: string, action: (args?: any) => Promise<T>, expiresIn: number = 5, force?: boolean): Promise<CachedValue<T>> {
        var item: CachedValue<T> = await deviceStorage.getItem(key);

        if(force) {
            //If forced old values get removed
            deviceStorage.removeItem(key);
        }

        if(force || item === null || item === undefined || item.refresh || new Date(item.expireDate).getTime() <= getCurrentDateTimeUTC().getTime()) {
            var newItem: T = await action();

            var cachedItem: CachedValue<T> = new CachedValue(newItem, false, expiresIn, false);
            cachedItem.refresh = false;

            deviceStorage.saveItem(key, cachedItem);
            return cachedItem;
        } else {
            item.isFromCache = true;
            return item;
        }
    },
    markAsOutdated<T>(key: string): void {
        var item: CachedValue<T> = deviceStorage.getItem(key);

        if(item === undefined) {
            return;
        }
        
        item.refresh = true;

        deviceStorage.saveItem(key, item);
    }
}

export default cachedStorage;