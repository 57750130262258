import { OrderState, OrderType } from "../models/orders/Order";
import { Salutation } from "../models/orders/Salutation";
import deviceStorage from "../services/deviceStorage";
import LanguageHandler from "../services/languageHandler";
import storageNames from "../services/storageNames";

export const hexToRgba = (hex: any, alpha = 1) => {
    const[r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const copyArray = (array: any[]) => {
    var copy: any[] = [];
    array.forEach(element => {
        copy.push(element);
    });
    return copy;
}

export const pushRange = (array: any[], addArray: any[]) => {
    addArray.forEach(element => {
        array.push(element);
    });
    return array;
}

export const removeFromArrayObject = (object: any, array: any[]) => {
    var copy = copyArray(array);
    
    var index = array.indexOf(object);

        
    copy.splice(index, 1);
    return copy;
}

export const removeFromArray = (id: number, array: any[]) => {
    var copy = copyArray(array);
        
    copy.splice(id, 1);
    return copy;
}

export const replaceInArray = (old: any, replace: any, array: any[]) => {
    var index = array.indexOf(old);

    var copy = copyArray(array);
    copy.splice(index, 1, replace);
    return copy;
}

export const updateInArray = (id: number, update: any, array: any[]) => {
    var copy = copyArray(array);
    
    copy[id] = update;
    return copy;
}

//Dates

export const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
  "Juli", "August", "September", "Oktober", "November", "Dezember"
];

export const dayShortNames = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export const dayShortNamesSorted = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

export const convertToLocalDate = (date: Date) => {
    date = new Date(date);
    return date;
}

export const getDayOfWeek = (date: Date) => {
    if(date.getDay() === 0) {
        return 6;
    } else {
        return date.getDay() - 1;
    }
}

export const getCurrentDateTimeUTC = () => {
    var date = new Date(); 
    var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc);
}

export const addMinutes = (date: Date, minutes: number) => {
    date = new Date(date);
    return new Date(date.getTime() + minutes*60000);
}

export const minusMinutes = (date: Date, minutes: number) => {
    date = new Date(date);
    return new Date(date.getTime() - minutes*60000);
}

export const minusHours = (date: Date, hours: number) => {
    date = new Date(date);
    return new Date(date.getTime() - hours*360000);
}

export const addDays = (date: Date, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
}

export const minusDays = (date: Date, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() - days);
    return date;
}

export const compareTime = (first: Date, second: Date) => {
    first = new Date(first);
    second = new Date(second);
    if(first.getHours() > second.getHours()) {
        return 1;
    } else if(first.getHours() < second.getHours()) {
        return 2;
    }

    if(first.getMinutes() > second.getMinutes()) {
        return 1;
    } else if(first.getMinutes() < second.getMinutes()) {
        return 2;
    }

    return 0;
}

export const differenceBetweenTimes = (first: Date, second: Date) => {
    first = new Date(first);
    second = new Date(second);

    return Math.round(Math.abs(second.getTime() - first.getTime()) / (60000));
}

export const differenceBetweenDates = (first: Date, second: Date) => {
    return Math.abs(getTimeOnly(second) - getTimeOnly(first));
}

export const getTimeOnly = (date: Date) => {
    date = new Date(date);
    return date.getHours() * 60 + date.getMinutes();
}

export const getGermanDate = (date: Date) => {
    date = new Date(date);
    return fixZero(date.getDate()) + "." + fixZero(date.getMonth() + 1) + "." + date.getFullYear();
}
/**
 * Converts a date to a beautiful string representation. Utc-Dates get converted to the local time.
 * @param date The date.
 * @param withTimeFormat Whether the format should be printed too.
 * @returns The date as a string
 */
export const getGermanTime = (date: Date, withTimeFormat: boolean = true) => {
    date = new Date(date);
    return fixZero(date.getHours()) + ":" + fixZero(date.getMinutes()) + (withTimeFormat ? " Uhr" : "");
}

export const isWeekend = (date: Date) => {
    if(date.getDay() === 0 || date.getDay() === 6) {
        return true;
    } else {
        return false;
    }
}

export const getGermanDayShortName = (date: Date) => {
    var date = new Date(date);
    return dayShortNames[date.getDay()];    
}

export const fixZero = (number: number) => {
    if(number < 10) {
        return "0" + number;
    }
    else {
        return number.toString();
    }
}

export const chunkArray = (array: any[], chunkSize: number) => {
    var chunks = [];

    var chunk = [];
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        
        chunk.push(element);
        if((index + 1) % 7 == 0) {
            chunks.push(chunk);
            chunk = [];
        } else {
            //Nothing
        }
    }

    if(chunk.length > 0) {
        chunks.push(chunk);
    }

    return chunks;
}

export const getBrowserLocales = (options: any = {}): string[] | undefined => {
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    if (!browserLocales) {
      return undefined;
    }
    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
}

export const translate = (textEntry: string, params?: string[] | null) => {
    return LanguageHandler.getInstance().getValue(textEntry, params);
}

export const getOrderTypeText = (type: OrderType): string => {
    if(type === OrderType.Local) {
        return translate("ORDER_TYPE_LOCAL");
    } else if(type === OrderType.Takeaway) {
        return translate("ORDER_TYPE_TAKEAWAY");
    } else if(type === OrderType.Delivery) {
        return translate("ORDER_TYPE_DELIVERY");
    } else {
        console.warn("Invalid order type");
        return translate("NO_INFORMATION");
    }
}

export const getOrderStatusText = (type: OrderState): string => {
    if(type === OrderState.Pending) {
        return translate("ORDER_STATE_PENDING");
    } else if(type === OrderState.InProgress) {
        return translate("ORDER_STATE_IN_PROGRESS");
    } else if(type === OrderState.DeliveryPending) {
        return translate("ORDER_STATE_DELIVERY_PENDING");
    } else if(type === OrderState.Done) {
        return translate("ORDER_STATE_DONE");
    } else if(type === OrderState.Cancelled) {
        return translate("ORDER_STATE_CANCELLED");
    } else {
        console.warn("Invalid order type");
        return translate("NO_INFORMATION");
    }
}

export const getPriceText = (price: number, currency: string) => {
    return price.toFixed(2).replace(".", ",") + currency;
}

export const isPriceValid = (price: string) => {
    var regex = new RegExp("[0-9]+.[0-9]{2}", "g");
    return regex.test(price);
}

export const removeOldBookingData = () => {
    deviceStorage.removeItem(storageNames.myTable);
    deviceStorage.removeItem(storageNames.tokenExp);
    deviceStorage.removeItem(storageNames.cartProducts);
    deviceStorage.removeItem(storageNames.booking);
    deviceStorage.removeItem(storageNames.orders);
    deviceStorage.removeItem(storageNames.activeBookings);
    deviceStorage.removeItem(storageNames.activeLocation);
    deviceStorage.removeItem(storageNames.activeRoom);
    deviceStorage.removeItem(storageNames.floorPlan);
    deviceStorage.removeItem(storageNames.hostKey);
    deviceStorage.removeItem(storageNames.user);
}

const days = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY"
]

export const getDayName = (dayofWeek: number) => {
    return translate(days[dayofWeek]);
}

export const getSalutationText = (salutation: Salutation) => {
    switch (salutation) {
        case Salutation.Mr: 
            return translate("MR");
        case Salutation.Ms: 
            return translate("MS");
            case Salutation.Company: 
                return translate("COMPANY");
        default:
            break;
    }
}

export const buildLocationBaseUrl = (locationId: string, locationName: string) => {
    var locationName = locationName?.toLowerCase().replace(/\ /g, "-");

    return `/locations/${locationId}/${locationName}`;
}

export const replaceBadCharsForUrl = (input: string) => {
    return input.replace("ä", "ae").replace("ö", "oe").replace("ü", "ue").replace("Ä", "ae").replace("Ö", "oe").replace("Ü", "ue").replace(" & ", "-").replace("&", "-").replace(", ", "-").replace(",", "-");
}

export const parseNumber = (value: string | undefined) => {
    if(!value) {
        return 0;
    }

    var id = parseFloat(value);

    if(isNaN(id)) {
        return 0;
    } else {
        return id;
    }
}

export const lightenDarkenColor = (col: string, amt: number) =>  {
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    var b = ((num >> 8) & 0x00FF) + amt;
    var g = (num & 0x0000FF) + amt;
    var newColor = g | (b << 8) | (r << 16);
    return newColor.toString(16);
  }