import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LanguageHandler from './services/languageHandler';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ScrollToTop from './core/components/ScrollToTop';
import { getAcceptedCookies } from './core/common/cookieUtils';
import CookieConsentDialog from './core/components/dialogs/CookieConsentDialog';
import { Helmet } from "react-helmet";
import { SnackbarProvider } from 'notistack';
import LanguageDialog from './components/dialogs/LanguageDialog';
import loadable from '@loadable/component'

const HomeScreen = loadable(() => import("./screens/HomeScreen"));
const LoginScreen = loadable(() => import("./screens/account/LoginScreen"));
const ContactScreen = loadable(() => import("./screens/ContactScreen"));
const CodesScreen = loadable(() => import("./screens/CodesScreen"));
const ImprintScreen = loadable(() => import("./screens/ImprintScreen"));
const PrivacyPolicyScreen = loadable(() => import("./screens/PrivacyPolicyScreen"));
const RegisterScreen = loadable(() => import("./screens/account/RegisterScreen"));
const ScannerScreen = loadable(() => import("./screens/ScannerScreen"));
const AccountScreen = loadable(() => import("./screens/account/AccountScreen"));
const ProfileScreen = loadable(() => import("./screens/account/ProfileScreen"));
const InvitationAcceptScreen = loadable(() => import("./screens/InvitationAcceptScreen"));
const LocationsScreen = loadable(() => import("./screens/LocationsScreen"));

const MenuBaseScreen = loadable(() => import("./screens/menu/MenuBaseScreen"));
const MenuOnlyScreen = loadable(() => import("./screens/menu/MenuOnlyScreen"));
const WatchlistScreen = loadable(() => import("./screens/WatchlistScreen"));
const ProductCategoriesScreen = loadable(() => import("./screens/menu/ProductCategoriesScreen"));
const ProductCategoryScreen = loadable(() => import("./screens/menu/ProductCategoryScreen"));
const LocationDetailsScreen = loadable(() => import("./screens/LocationDetails"));
const CartScreen = loadable(() => import("./screens/checkout/CartScreen"));
const CheckoutScreen = loadable(() => import("./screens/checkout/CheckoutScreen"));
const CheckoutSuccessScreen = loadable(() => import("./screens/checkout/CheckoutSuccessScreen"));
const AddressScreen = loadable(() => import("./screens/checkout/AddressScreen"));
const AddressAddScreen = loadable(() => import("./screens/checkout/AddressAddScreen"));
const PaymentMethodsScreen = loadable(() => import("./screens/payment-details/PaymentMethodsScreen"));
const PaymentCreateMethodScreen = loadable(() => import("./screens/payment-details/PaymentCreateMethodScreen"));

const BookingScreen = loadable(() => import("./screens/BookingScreen"));
const BookingMenuBaseScreen = loadable(() => import("./screens/menu/BookingMenuBaseScreen"));
const BookingSettingsScreen = loadable(() => import("./screens/BookingSettingsScreen"));
const OrdersScreen = loadable(() => import("./screens/OrdersScreen"));
const PaymentsScreen = loadable(() => import("./screens/PaymentsScreen"));
const OrderConfirmScreen = loadable(() => import("./screens/order-details/OrderConfirmScreen"));
const OrderSuccessScreen = loadable(() => import("./screens/order-details/OrderSuccessScreen"));
const PaymentConfirmScreen = loadable(() => import("./screens/payment-details/PaymentConfirmScreen"));
const PaymentSuccessScreen = loadable(() => import("./screens/payment-details/PaymentSuccessScreen"));

const App = () => {

  const [initialized, setInitialized] = useState<boolean>(false);
  const [languageDialogOpen, setLanguageDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if(getAcceptedCookies().length === 0) {
      return;
    }

    if(!initialized) {
      var code = LanguageHandler.getInstance().getUserLanguage();
      LanguageHandler.getInstance().loadLanguageByCode(code, false, false);
      LanguageHandler.getInstance().refresh();

      setInitialized(true);
    }
  }, [])


  const onCloseDialog = () => {
    setLanguageDialogOpen(false);
  }

  document.addEventListener('onLanguageChange', function() {
    window.location.reload();
  });

  document.addEventListener('onLanguageDialogOpen', function() {
    setLanguageDialogOpen(true);
  });

  return (
    <div id="app" className="App">
      <Helmet>
        <title>Place to be | Venisly</title>
        <meta name="description" content="Bestelle und bezahle im Restaurant bequem von deinem Smartphone aus. Die All-In-One Lösung für die Gastronomie." />
      </Helmet>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ScrollToTop />
            <CookieConsentDialog />
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            />
            <LanguageDialog
                open={languageDialogOpen}
                onClose={onCloseDialog}
            />
            <Routes>
              <Route path="/">
                <Route index element={<HomeScreen />} />
              </Route>
              <Route path="home" element={<HomeScreen />} />
              <Route path="login" element={<LoginScreen />} />
              <Route path="contact" element={<ContactScreen />} />
              <Route path="codes/:codeId" element={<CodesScreen />} />
              <Route path="impressum" element={<ImprintScreen />} />
              <Route path="datenschutzerklaerung" element={<PrivacyPolicyScreen />} />
              <Route path="register" element={<RegisterScreen />} />
              <Route path="scan" element={<ScannerScreen />} />
              <Route path="account" element={<AccountScreen />} >
                <Route path="profile" element={<ProfileScreen />} />
              </Route>
              <Route path="invitations/:invitationId">
                <Route path="accept" element={<InvitationAcceptScreen />} />
              </Route>
              <Route path="locations" element={<LocationsScreen />} />

              <Route path="locations/:locationId">
                <Route path="menu" element={<MenuBaseScreen/>}>
                  <Route index element={<MenuOnlyScreen />} />
                  <Route path="watchlist" element={<WatchlistScreen/>} />
                  <Route path=":mainCategory">
                  <Route index element={<ProductCategoriesScreen />} />
                    <Route path=":category" element={<ProductCategoryScreen/>} />
                  </Route>
                </Route>
                <Route path="details" element={<LocationDetailsScreen />} />
                <Route path="watchlist" element={<WatchlistScreen />} />
                <Route path=":locationName">
                  <Route index element={<LocationDetailsScreen />} />
                  <Route path="cart" element={<CartScreen/>} />
                  <Route path="checkout">
                    <Route index element={<CheckoutScreen />} />
                    <Route path="success" element={<CheckoutSuccessScreen/>} />
                    <Route path="address" >
                      <Route index element={<AddressScreen />} />
                      <Route path="add" element={<AddressAddScreen/>} />
                    </Route>
                    <Route path="paymentmethods">
                      <Route index element={<PaymentMethodsScreen />} />
                      <Route path="create" element={<PaymentCreateMethodScreen />} />
                    </Route>
                    <Route path="confirm" element={<CheckoutScreen/>} />
                  </Route>
                </Route>
              </Route>

              <Route path="locations/:locationId/tables/:tableCode" element={<BookingScreen />}>
                <Route path="menu" element={<BookingMenuBaseScreen/>}>
                  <Route index element={<MenuOnlyScreen />} />
                  <Route path="watchlist" element={<WatchlistScreen/>} />
                  <Route path=":mainCategory">
                  <Route index element={<ProductCategoriesScreen />} />
                    <Route path=":category" element={<ProductCategoryScreen/>} />
                  </Route>
                </Route>
                <Route path="booking" element={<BookingSettingsScreen />} />
                <Route path="orders">
                  <Route index element={<OrdersScreen />} />
                  <Route path="confirm" element={<OrderConfirmScreen />} />
                  <Route path="success" element={<OrderSuccessScreen />} />
                </Route>
                <Route path="payments">
                  <Route index element={<PaymentsScreen />} />
                  <Route path="confirm" element={<PaymentConfirmScreen />} />
                  <Route path="success" element={<PaymentSuccessScreen />} />
                  <Route path="methods">
                    <Route index element={<PaymentMethodsScreen />} />
                    <Route path="create" element={<PaymentCreateMethodScreen />} />
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </LocalizationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
