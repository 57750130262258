import { getBrowserLocales } from "../common/utils";
import Language from "../models/Language";
import LanguageValue from "../models/LanguageValue";
import deviceStorage from "./deviceStorage";
import LocationHandler from "./locationHandler";
import RequestManager from "./requestManager";
import storageNames from "./storageNames";

export default class LanguageHandler {

    static myInstance: LanguageHandler | null = null;

    _languageValues: LanguageValue[] = [];

    /**
     * @returns {LanguageHandler}
     */
    static getInstance(): LanguageHandler {
        if (LanguageHandler.myInstance === null) {
            LanguageHandler.myInstance = new LanguageHandler();
            var languageValues = deviceStorage.getItem(storageNames.languagePackage);

            if(!languageValues) {
                languageValues = [];
            }

            var locationLanguageValues = deviceStorage.getItem(storageNames.locationLanguagePackage);

            if(!locationLanguageValues) {
                locationLanguageValues = [];
            }
            
            LanguageHandler.myInstance._languageValues = languageValues;
            LanguageHandler.myInstance._languageValues.push(...locationLanguageValues);
        }

        return this.myInstance!;
    }

    /**
     * Gets the value of the text entry for the current selected language.
     */
    getValue(textEntry: string, params: string[] | null = null): string {
        if(textEntry === null || textEntry === undefined) {
            return "EMPTY KEY SUPPLIED";
        }

        if(textEntry.toUpperCase() !== textEntry) {
            //The text entry is not a managed text entry. All managed text entries are in uppercase.
            return textEntry;
        }

        var entry = this._languageValues.find(function (element) {
            return element.textEntry === textEntry;
        });

        
        if(entry === undefined) {
            return textEntry;
        }

        //Create copy of language entry
        entry = {...entry};

        if(params != null && params.length > 0) {
            for (let index = 0; index < params.length; index++) {
                const element = params[index];
                
                entry.value = entry.value.replace("{" + index + "}", element);
            }
        }

        return entry.value;
    }

    /**
     * Returns the language tag of the current selected language.
     */
    getUserLanguage(): string {
        var language = deviceStorage.getItem(storageNames.language);

        if(language === null || language === undefined) {
            var languages = getBrowserLocales({languageCodeOnly: true});

            if(languages === undefined) {
                return "DE";
            } else {
                return languages[0]!.toUpperCase();
            }
        } else {
            return language;
        }
    }

    async refreshLocationPackage(locationCode: string): Promise<void> {
        var location = await LocationHandler.getSelectedLocation(locationCode);
        var languagePackage = await RequestManager.getInstance().getLanguageByCode(LanguageHandler.getInstance().getUserLanguage(), location.value.code!);
        
        this.setLocationPackage(languagePackage);
    }

    setLocationPackage(languageValues: LanguageValue[]): void {
        deviceStorage.saveItem(storageNames.locationLanguagePackage, languageValues);
        this.refresh();
    }

    async loadLanguage(language: Language): Promise<void> {
        return await this.loadLanguageByCode(language.abbreviation, true, true);
    }

    async loadLanguageByCode(code: string, loadLocation: boolean = false, throwEvent: boolean = false): Promise<void> {
        deviceStorage.saveItem(storageNames.language, code);

        if(loadLocation) {
            var selectedLocation = await LocationHandler.getCachedLocation();

            if(loadLocation && selectedLocation) {
                var locationPackagePromise = RequestManager.getInstance().getLanguageByCode(code, selectedLocation.value.code);
                var languagePackagePromise = RequestManager.getInstance().getLanguageByCode(code);
            
                let [locationPackage, languagePackage] = await Promise.all([locationPackagePromise, languagePackagePromise]);
            
                //Setting the language packages
                deviceStorage.saveItem(storageNames.languagePackage, languagePackage);
                deviceStorage.saveItem(storageNames.locationLanguagePackage, locationPackage);
            } 
        } else {
            var languageValues = await RequestManager.getInstance().getLanguageByCode(code);
            deviceStorage.saveItem(storageNames.languagePackage, languageValues);
        }
        
        if(throwEvent) {
            const event = new CustomEvent('onLanguageChange');

            //dispatch the event
            document.dispatchEvent(event);
        }
    }

    refresh(): void {
        var languageValues = deviceStorage.getItem(storageNames.languagePackage);

        if(!languageValues) {
            languageValues = [];
        }

        var locationLanguageValues = deviceStorage.getItem(storageNames.locationLanguagePackage);

        if(!locationLanguageValues) {
            locationLanguageValues = [];
        }

        this._languageValues = languageValues;
        this._languageValues.push(...locationLanguageValues);
    }
}