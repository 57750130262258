import { Button, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import TransitionsDialog from "../../core/components/dialogs/TransitionsDialog";
import Language from "../../models/Language";
import LanguageHandler from "../../services/languageHandler";
import cachedStorage from "../../services/cachedStorage";
import RequestManager from "../../services/requestManager";
import storageNames from "../../services/storageNames";
import Flag from "../Flag";

type LanguageDialogType = {
    onClose: () => void;
    open: boolean;
}

const LanguageDialog = ({...props}: LanguageDialogType) => {
    const [languages, setLanguages] = useState<Language[]>([]);
    const [language, setLanguage] = useState<Language | null>(null);

    useEffect(() => {
        const init = async () => {
            if(props.open === true) {
                var languages = await cachedStorage.getValue<Language[]>(storageNames.languages, RequestManager.getInstance().getLanguages, 240);

                let code = LanguageHandler.getInstance().getUserLanguage();
                var language = languages.value.find(l => l.abbreviation == code);

                setLanguage(language!)
                setLanguages(languages.value);
            }
        }

        init();

        return () => {

        };
    }, [props.open])

    const handleLanguageChange = async (value: string) => {
        var languageId = parseFloat(value);

        var language = languages.find(l => l.id === languageId);

        if(!language) {
            return;
        }

        await LanguageHandler.getInstance().loadLanguage(language);
        setLanguage(language);
    }

    return (
        <TransitionsDialog
            open={props.open}
            onClose={props.onClose}
            ariaDescribedBy="language-content"
            title={"Wähle eine Sprache"}
        >
            <TextField
                select
                label="Sprache"
                variant="outlined"
                value={language?.id}
                fullWidth
                onChange={(event) => handleLanguageChange(event.target.value)}
                sx={{
                    mt: 1
                }}
            >
                {languages.map(lang => {
                    return (
                        <MenuItem 
                            key={lang.id} 
                            value={lang.id}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <Flag
                                    countryCode={lang.abbreviation}
                                    height={16}
                                    width={16}
                                />
                                <Typography
                                    sx={{
                                        ml: 1
                                    }}
                                >
                                    {lang.englishName}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    );
                })}
            </TextField>
        </TransitionsDialog>
    );
}

export default LanguageDialog;