const storageNames =  {
    tokenExp: "tokenExp",
    language: "language",
    languages: "languages",
    languagePackage: "languagePackage",
    locationLanguagePackage: "locationLanguagePackage",
    user: "user",
    serverDomain: "serverDomain",
    wsDomain: "wsDomain",
    activeLocation: "activeLocation",
    location: "location",
    cashRegister: "cashRegister",
    orders: "orders",
    payments: "payments",
    paymentMethods: "paymentMethods",
    floorPlan: "floorPlan",
    activeRoom: "activeRoom",
    mainCategories: "mainCategories",
    categories: "categories",
    booking: "booking",
    myTable: "myTable",
    /**
     * The cart prodcucts of online ordering or local ordering.
     */
    cartProducts: "cartProducts",
    selectedItems: "selectedItems",
    locations: "locations",
    tags: "tags",
    /**
     * The last selected category of the current menu.
     */
    tmpSelectedMainCategory: "tmpSelectedMainCategory",
    tmpPaymentOption: "tmpPaymentOption",
    tmpInvoiceAddress: "tmpInvoiceAddress",
    tmpDeliveryAddress: "tmpDeliveryAddress",
    tmpOrderType: "tmpOrderType",
    tmpLocationCode: "tmpLocationId",
    tmpLocationName: "tmpLocationName",
    /**
     * For non registered users to cache the selected user payment method.
     */
    tmpUserPaymentMethod: "tmpUserPaymentMethod",
    mainAddress: "mainAddress",
    qrCode: "qrCode",
    selectedOrderProducts: "selectedOrderProducts",
    activeBookings: "activeBookings",
    options: "options",
    hostKey: "hk"
}

export default storageNames;