import axios, { AxiosResponse, CancelTokenSource, CancelTokenStatic } from "axios";
import { getCurrentDateTimeUTC, minusMinutes } from "../common/utils";
import SimpleUserData from "../models/SimpleUserData";
import deviceStorage from "./deviceStorage";
import storageNames from "./storageNames";
import jwt_decode from 'jwt-decode';
import domains from "./domains";
import User from "../models/User";
import CreateRequest from "../models/CreateRequest";
import Location from "../models/Location";
import ProductCategory from "../models/products/ProductCategory";
import Product from "../models/products/Product";
import LanguageValue from "../models/LanguageValue";
import Language from "../models/Language";
import Order, { OrderState } from "../models/orders/Order";
import PaymentTO from "../models/payments/PaymentTO";
import PaymentMethod from "../models/payments/PaymentMethod";
import { PaymentState } from "../models/payments/PaymentState";
import Room from "../models/FloorPlan/Room";
import Table from "../models/FloorPlan/Table";
import Floor from "../models/FloorPlan/Floor";
import Booking from "../models/Booking";
import BookingRequest from "../models/BookingRequest";
import CreateOrderRequest from "../models/orders/requests/CreateOrderRequest";
import CreatePaymentRequest from "../models/payments/CreatePaymentRequest";
import UserPaymentMethod from "../models/payments/UserPaymentMethod";
import BookingUser from "../models/BookingUser";
import LocationSearchRequest from "../models/LocationSearchRequest";
import UserFileTO from "../models/UserFileTO";
import Tag from "../models/Tag";
import StaffMember from "../models/StaffMember";
import OpeningHour from "../models/OpeningHour";
import LocationImage from "../models/LocationImage";
import Review from "../models/Review";
import OrderProduct from "../models/orders/OrderProduct";
import PreviewOrderRequest from "../models/PreviewOrderRequest";
import Address from "../models/orders/Address";
import LocationPlan from "../models/LocationPlan";
import StaffInvitation from "../models/StaffInvitation";
import RegisterUserRequest from "../models/RegisterUserRequest";
import Allergen from "../models/Allergen";
import Additive from "../models/Additive";
import MainProductCategory from "../models/products/MainProductCategory";
import Option from "../models/products/Option";
import Pagination from "../models/Pagination";
import BookingCreationResult from "../models/BookingCreationResult";
import TableQrCode from "../models/FloorPlan/TableQrCode";
import EnvironmentHandler from "./environmentHandler";
import Ticket from "../models/Ticket";

const tokenAxios = axios.create({
    withCredentials: true
});
const gatewayAxios = axios.create({
    withCredentials: true
});
gatewayAxios.defaults.withCredentials = true;

const uploadAxios = axios.create({
    withCredentials: true
});

const baseUrlRequest = "/gateway/request";
const baseUrlShort = "/api";

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
};

function BuildUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query: string = "") {
    if(parameter == null) {
        return baseUrlRequest + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return baseUrlRequest + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}

function BuildUploadUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query = "") {
    if(parameter == null) {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}

const getDomain = () => {
    var serverDomain = deviceStorage.getItem(storageNames.serverDomain);
    //serverDomain = null;

    if(serverDomain === null) {
        if(EnvironmentHandler.isLocalEnvironment()) {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_local);
            return domains.gateway_local;
        } else if(EnvironmentHandler.isTestEnvironment()) {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_test);
            return domains.gateway_test;
        } else {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_prod);
            return domains.gateway_prod;
        }
    } else {
        return serverDomain;
    }
}

tokenAxios.interceptors.request.use((config) => {
    var domain = getDomain();

    config.baseURL = domain + baseUrlShort;
    config.headers!['Content-Type'] = 'application/json';
    return config;
}, (error) => {
    console.log("Token could not be refreshed: " + JSON.stringify(error));
    return error;
});

gatewayAxios.interceptors.request.use(async (config) => {
    var domain = getDomain();
    await tokenHandler.refresh();
    
    config.baseURL = domain + baseUrlShort;
    config.headers!['Content-Type'] = 'application/json';
    config.withCredentials = true;
    return config;
}, (error) => {
    console.log("Request failed: " + error.response["_response"]);
    return Promise.reject(error);
});

gatewayAxios.interceptors.response.use(async (response) => {
    //console.log("Request successful:" + JSON.stringify(response));
    if(response.data.token) {
        deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);
    }

    if(response.config.responseType === "arraybuffer") {
        var rAsText = JSON.stringify(response.request);

        var beginText = "\"_response\":\"";
        var start = rAsText.indexOf(beginText) + beginText.length;

        var imageBase64 = rAsText.substring(
            start, 
            rAsText.indexOf("\"", start)
        );
        return "data:image/png;base64," + imageBase64;
    }

    return response.data.data !== undefined ? response.data.data : response.data;
}, (error) => {
    //if(error.response.status === 401) {
        //NavigationActions.navigate({ routeName: 'home' });
        //deviceStorage.removeItem(storageNames.apiToken);
    //}

    console.log("Error: " + JSON.stringify(error.response));
    console.log("Request failed (response):" + JSON.stringify(error));
    return Promise.reject(error);
});

uploadAxios.interceptors.request.use(async (config) => {
    var domain = getDomain();
    await tokenHandler.refresh();

    config.baseURL = domain + baseUrlShort;
    config.headers!['Content-Type'] = 'multipart/form-data';
    return config;
}, (error) => {
    console.log("Request failed: " + error.response["_response"]);
    return Promise.reject(error);
});

uploadAxios.interceptors.response.use(async (response) => {
    //console.log("Request successful:" + JSON.stringify(response));
    if(response.data.token) {
        deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);
    }

    if(response.config.responseType === "arraybuffer") {
        var rAsText = JSON.stringify(response.request);

        var beginText = "\"_response\":\"";
        var start = rAsText.indexOf(beginText) + beginText.length;

        var imageBase64 = rAsText.substring(
            start, 
            rAsText.indexOf("\"", start)
        );
        return "data:image/png;base64," + imageBase64;
    }

    return response.data.data !== undefined ? response.data.data : response.data;
}, (error) => {
    //if(error.response.status === 401) {
        //NavigationActions.navigate({ routeName: 'home' });
        //deviceStorage.removeItem(storageNames.apiToken);
    //}

    console.log("Error: " + JSON.stringify(error.response));
    console.log("Request failed (response):" + JSON.stringify(error));
    return Promise.reject(error);
});

export const tokenHandler = {
    async refresh(force: boolean = false) {
        var expDate = deviceStorage.getItem(storageNames.tokenExp);
        if(!expDate) {
            return;
        }

        if (force || minusMinutes(expDate, 1).getTime() <= getCurrentDateTimeUTC().getTime()) { // If 1 minute or less left refresh api token
            
            try {
                let response = await tokenAxios.post("/token/refresh", "{}");
                deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);
            } catch(e) {
                return;
            }
        }
    },
    /*
    async getRoles() {
        var apiToken = deviceStorage.getItem(storageNames.apiToken);
        var accessToken: any = jwt_decode(apiToken.accessToken);
        return accessToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    },
    async hasRole(roleName: string) {
        var roles = await tokenHandler.getRoles();
        if(roles.includes(roleName)) {
            return true;
        }
        return false;
    },
    async getRoleName() {
        var roles = await tokenHandler.getRoles();
        if(roles.includes("Location Host")) {
            return "Partner-Konto";
        } else if(roles.includes("Pending Location Host")) {
            return "Partner-Konto (ausstehend)";
        } else {
            return "Standard-Konto";
        }
    },
    async isHost() {
        var hasPendingHostRole = await tokenHandler.hasRole("Pending Location Host");
        var hasHostRole = await tokenHandler.hasRole("Location Host");
        return hasPendingHostRole || hasHostRole;
    },
    */
}

export default class RequestManager {

    static myInstance: RequestManager;

    static _cancelToken: CancelTokenSource;



    /**
     * @returns {RequestManager}
     */
    static getInstance(): RequestManager {
        if (RequestManager.myInstance === null || RequestManager.myInstance === undefined) {
            RequestManager.myInstance = new RequestManager();
            RequestManager._cancelToken = axios.CancelToken.source();
        }

        return this.myInstance;
    }

    async getUser(): Promise<User> {
        var domain = await getDomain();

        return await gatewayAxios.get(domain + baseUrlShort + "/user/find");
    }

    /**
     * Updates the user service user.
     * @param user The user that gets updated.
     * @returns The updated user.
     */
    async updateUser(user: User): Promise<void> {
        return await gatewayAxios.put("/user/update", user, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async UpdateFcmToken(token: string) {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "users", "token"), token, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async login(simpleUser: SimpleUserData): Promise<any> {
        return await gatewayAxios.post("/user/login", simpleUser, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async register(user: RegisterUserRequest) {
        return await gatewayAxios.post("/user/register", user, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createGuest(): Promise<any> {
        return await gatewayAxios.post("/user/createguest?userInterfaceName=VenislyApp", "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async logout() {
        return await gatewayAxios.post("/user/logout", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async createTicket(ticket: Ticket): Promise<void> {
        return await gatewayAxios.post(BuildUrl("user_service", "tickets", "create"), ticket, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getCashRegister(locationId: number) {
        return await gatewayAxios.get(BuildUrl("cash_register_service", "locationCashRegisters", "get", locationId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async linkCashRegister(createRequest: CreateRequest) {
        return await gatewayAxios.post(BuildUrl("cash_register_service", "locationCashRegisters", "create"), createRequest, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async importProducts(locationId: number) {
        return await gatewayAxios.put(BuildUrl("cash_register_service", "products", "import", locationId.toString()), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createProduct(product: Product): Promise<Product> {
        return await gatewayAxios.post(BuildUrl("booking_service", "products", "create"), product, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateProduct(productId: number, product: Product) {
        return await gatewayAxios.put(BuildUrl("booking_service", "products", "update", productId.toString()), product, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async deleteProduct(productId: number) {
        return await gatewayAxios.delete(BuildUrl("booking_service", "products", "delete", productId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
            data: "{}" //Requires empty body
        });
    }

    async getProductAllergens(id: number): Promise<Allergen[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "products", "allergens", id.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getProductAdditives(id: number): Promise<Additive[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "products", "additives", id.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getProductCategories(mainCategoryId: number): Promise<ProductCategory[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "mainproductcategories", "productcategories", mainCategoryId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getMainProductCategories(locationCode: string): Promise<MainProductCategory[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "mainproductcategories", "get", null, `&locationCode=${locationCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    /**
     * 
     * @param locationCode 
     * @returns 
     * @deprecated
     */
    async getMenu(locationCode: string): Promise<MainProductCategory[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "products", "menu", locationCode), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    /**
     * Gets the menu options of a location.
     * @param locationId The ID of the location.
     * @returns The menu options of the location.
     */
    async getOptions(locationCode: string): Promise<Option[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "options", "get", null, `&locationCode=${locationCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

 
    
    async uploadProductImage(productId: number, file: any) {
        const formData = new FormData();
        formData.append("files", file);

        return await uploadAxios.post(BuildUploadUrl("booking_service", "products", "upload", productId.toString()), formData, {
            cancelToken: RequestManager._cancelToken.token
        });
    }
 
    async uploadIntroImage(locationId: number, file: any) {
        const formData = new FormData();
        formData.append("files", file);

        return await uploadAxios.post(BuildUploadUrl("booking_service", "products", "uploadintroimage", locationId.toString()), formData, {
            cancelToken: RequestManager._cancelToken.token
        });
    }

    async getOwnLocations(): Promise<Location[]> {
        return await gatewayAxios.get(BuildUrl("location_service", "location", "getownlocations"), {
            cancelToken: RequestManager._cancelToken.token,
            withCredentials: true
        });
    }

    async getLocations(request: LocationSearchRequest): Promise<Pagination> {
        return await gatewayAxios.post(BuildUrl("location_service", "location", "getlocations"), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    /**
     * Gets simple information about a location.
     * @param locationCode The ID of the location.
     * @returns The requested location.
     */
    async getLocation(locationCode: string): Promise<Location> {
        return await gatewayAxios.get(BuildUrl("location_service", "Location", "Get", locationCode.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    /**
     * Gets detailed information about a location.
     * @param id The ID of the location.
     * @returns The requested location.
     */
    async getLocationDetails(id: number): Promise<Location> {
        return await gatewayAxios.put(BuildUrl("location_service", "Location", "details", id.toString()), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getLocationFloorPlan(id: number): Promise<Location> {
        return await gatewayAxios.get(BuildUrl("location_service", "Location", "FloorPlan", id.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getOpeningHours(locationId: number): Promise<OpeningHour[]> {
        return await gatewayAxios.get(BuildUrl("location_service", "openinghour", "find", locationId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateOpeningHours(locationId: number, openingHours: OpeningHour[]): Promise<OpeningHour[]> {
        return await gatewayAxios.put(BuildUrl("location_service", "openinghour", "update", locationId.toString()), openingHours, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getLocationImages(locationId: number): Promise<LocationImage[]> {
        return await gatewayAxios.get(BuildUrl("file_service", "image", "getall", locationId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async uploadLocationImages(locationId: number, files: File[]): Promise<UserFileTO[]> {
        const formData = new FormData();
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            formData.append("files", file);
        }

        return await uploadAxios.post(BuildUploadUrl("file_service", "image", "upload", locationId.toString()), formData, {
            cancelToken: RequestManager._cancelToken.token
        });
    }

    async deleteUserFile(id: number): Promise<void> {
        return await gatewayAxios.delete(BuildUrl("file_service", "image", "delete", id.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async uploadThumbnail(locationId: number, file: any): Promise<UserFileTO> {
        const formData = new FormData();
        formData.append("files", file);

        return await uploadAxios.post(BuildUploadUrl("file_service", "image", "UploadThumbnail", locationId.toString()), formData, {
            cancelToken: RequestManager._cancelToken.token
        });
    }

    async getStaffUsers(locationId: number): Promise<StaffMember[]> {
        return await gatewayAxios.get(BuildUrl("quind_user_service", "staff", "all", locationId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async removeStaffUser(userId: number, locationId: number): Promise<void> {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "staff", "remove", null, `&userId=${userId}&locationId=${locationId}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async addStaffUserRole(userId: number, locationId: number, role: string): Promise<void> {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "staff", "role", null, `&userId=${userId}&locationId=${locationId}&role=${role}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async removeStaffUserRole(userId: number, locationId: number, role: string): Promise<void> {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "staff", "roleremove", null, `&userId=${userId}&locationId=${locationId}&role=${role}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getOpenStaffUserInvitations(locationId: number): Promise<StaffInvitation[]> {
        var status: number = 0;

        return await gatewayAxios.get(BuildUrl("quind_user_service", "staffinvitations", "find", null, `&locationId=${locationId}&status=${status}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getStaffUserInvitation(): Promise<StaffInvitation> {
        return await gatewayAxios.get(BuildUrl("quind_user_service", "staffinvitations", "get", null), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createStaffUserInvitation(locationId: number, payload: string, payloadType: number): Promise<void> {
        return await gatewayAxios.post(BuildUrl("quind_user_service", "staffinvitations", "create", null, `&locationId=${locationId}&payload=${payload}&payloadType=${payloadType}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async acceptStaffUserInvitation(id: number): Promise<void> {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "staffinvitations", "accept", id.toString()), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async declineStaffUserInvitation(id: number, locationId : number): Promise<void> {
        return await gatewayAxios.put(BuildUrl("quind_user_service", "staffinvitations", "decline", id.toString(), `&locationId=${locationId}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getStaffRoles(): Promise<string[]> {
        return await gatewayAxios.get(BuildUrl("quind_user_service", "staff", "roles"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getTags(): Promise<Tag[]> {
        return await gatewayAxios.get(BuildUrl("location_service", "tags", "get"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async getLanguages(): Promise<Language[]> {
        return await gatewayAxios.get(BuildUrl("communication_service", "language", "All"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getLanguage(id: number) {
        return await gatewayAxios.get(BuildUrl("communication_service", "language", "Find", id.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getLanguageByCode(code: string, locationCode: string | null = null): Promise<LanguageValue[]> {
        return await gatewayAxios.get(BuildUrl("communication_service", "language", "FindByCode", code, locationCode === null ? "" : "&locationCode=" + locationCode), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    /**
     * 
     * @returns 
     */
    async isBookingActive(bookingCode: string): Promise<boolean> {
        return await gatewayAxios.get(BuildUrl("booking_service", "bookings", "isactive", null, `&bookingCode=${bookingCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createBooking(request: BookingRequest): Promise<BookingCreationResult | Booking> {
        return await gatewayAxios.post(BuildUrl("booking_service", "bookings", "create"), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createBookingForOthers(request: BookingRequest, locationId: number): Promise<Booking> {
        return await gatewayAxios.post(BuildUrl("booking_service", "bookings", "createforothers", null, `&locationId=${locationId}`), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateBooking(booking: Booking, hostKey: string): Promise<Booking> {
        return await gatewayAxios.put(BuildUrl("booking_service", "bookings", "update", "0", `&bookingCode=${booking.code}&hostkey=${hostKey}`), booking, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async joinBooking(locationCode: string, tableCode: string, joinCode: string | null): Promise<Booking> {
        return await gatewayAxios.put(BuildUrl("booking_service", "bookings", "join", "", `&locationCode=${locationCode}&tableCode=${tableCode}&joinCode=${joinCode}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getBookingsOfLocation(locationId: number): Promise<Booking[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "bookings", "ActiveOfLocation", null, `&locationId=${locationId}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getBookingForTable(tableCode: string): Promise<Booking> {
        return await gatewayAxios.get(BuildUrl("booking_service", "bookings", "GetForTable", null, `&tableCode=${tableCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getBookingUsers(bookingCode: string, hostKey: string): Promise<BookingUser[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "bookings", "getusers", "0", `&bookingCode=${bookingCode}&hostKey=${encodeURIComponent(hostKey)}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async removeBookingUser(bookingId: number, code: string) {
        return await gatewayAxios.put(BuildUrl("booking_service", "bookings", "removeuser", bookingId.toString(), `&userCode=${code}`), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getOrderPreview(request: PreviewOrderRequest): Promise<PaymentTO> {
        return await gatewayAxios.put(BuildUrl("payment_service", "payments", "previeworder"), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async completeBooking(bookingId: number): Promise<void> {
        return await gatewayAxios.put(BuildUrl("booking_service", "bookings", "complete", bookingId.toString()), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createOrder(request: CreateOrderRequest): Promise<Order> {
        return await gatewayAxios.post(BuildUrl("booking_service", "orders", "Create"), request);
    }

    async getBookingOrders(bookingCode: string): Promise<Order[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "bookings", "orders", "0", `&bookingCode=${bookingCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getOrdersHost(locationId: number): Promise<Order[]> {
        return await gatewayAxios.get(BuildUrl("booking_service", "orders", "active", locationId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateOrderState(orderState: OrderState, order: Order) {
        if(orderState === OrderState.InProgress) {
            return await gatewayAxios.put(BuildUrl("booking_service", "orders", "inprogress"), order);
        } else if(orderState === OrderState.DeliveryPending) {
            return await gatewayAxios.put(BuildUrl("booking_service", "orders", "delpending"), order);
        } else if(orderState === OrderState.Done) {
            return await gatewayAxios.put(BuildUrl("booking_service", "orders", "done"), order);
        } else if(orderState === OrderState.Cancelled) {
            return await gatewayAxios.put(BuildUrl("booking_service", "orders", "cancel"), order);
        }
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        return await gatewayAxios.get(BuildUrl("payment_service", "payments", "methods"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async cashReceived(id: number, locationId: number, tip: number) {
        return await gatewayAxios.put(BuildUrl("payment_service", "payments", "cashReceived", id.toString(), "&locationId=" + locationId + "&tip=" + tip), "{}");
    }

    async cashedUp(id: number, locationId: number, tip: number, pan: string) {
        return await gatewayAxios.put(BuildUrl("payment_service", "payments", "cashedup", id.toString(), "&locationId=" + locationId + "&tip=" + tip + "&pan=" + pan), "{}");
    }

    async getPayments(locationId: number, paymentState: PaymentState | null = null): Promise<PaymentTO[]> {
        return await gatewayAxios.get(BuildUrl("payment_service", "payments", "get", locationId.toString(), paymentState === null ? "" : "&paymentState=" + paymentState), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    
    async updateLocation(location: Location) {
        return await gatewayAxios.put(BuildUrl("location_service", "Location", "update", location.id?.toString()), location, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getRoom(roomId: number): Promise<Room> {
        return await gatewayAxios.get(BuildUrl("location_service", "room", "get", roomId.toString()), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createRoom(room: Room): Promise<Room> {
        return await gatewayAxios.post(BuildUrl("location_service", "room", "create"), room, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateRoom(room: Room) {
        return await gatewayAxios.post(BuildUrl("location_service", "room", "update", room.id?.toString()), room, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createFloor(floor: Floor): Promise<Floor> {
        return await gatewayAxios.post(BuildUrl("location_service", "floor", "create"), floor, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async createTable(table: Table) {
        return await gatewayAxios.post(BuildUrl("location_service", "table", "create"), table, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateTable(table: Table) {
        return await gatewayAxios.put(BuildUrl("location_service", "table", "update", table.id?.toString()), table, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async deleteTable(tableId: number, locationId: number) {
        return await gatewayAxios.delete(BuildUrl("location_service", "table", "delete", tableId?.toString(), "&locationId=" + locationId), {
            cancelToken: RequestManager._cancelToken.token,
            data: "{}"
        });
    }

    async getPaymentPreview(request: CreatePaymentRequest): Promise<PaymentTO> {
        return await gatewayAxios.put(BuildUrl("payment_service", "payments", "preview"), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async createPayment(request: CreatePaymentRequest): Promise<PaymentTO> {
        return await gatewayAxios.post(BuildUrl("payment_service", "payments", "pay"), request, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async getUserPaymentMethods(): Promise<UserPaymentMethod[]> {
        return await gatewayAxios.get(BuildUrl("payment_service", "userpaymentmethods", "get"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async createUserPaymentMethod(userPaymentMethod: UserPaymentMethod): Promise<UserPaymentMethod> {
        return await gatewayAxios.post(BuildUrl("payment_service", "userpaymentmethods", "create"), userPaymentMethod, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async setPaymentMethodAsDefault(userPaymentMethod: UserPaymentMethod): Promise<UserPaymentMethod> {
        return await gatewayAxios.put(BuildUrl("payment_service", "userpaymentmethods", "default", userPaymentMethod.id.toString()), "{}", {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createReview(review: Review): Promise<Review> {
        return await gatewayAxios.post(BuildUrl("location_service", "reviews", "create"), review, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async updateReview(review: Review): Promise<Review> {
        return await gatewayAxios.put(BuildUrl("location_service", "reviews", "update", review.id.toString()), review, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async deleteReview(reviewId: number): Promise<Review> {
        return await gatewayAxios.delete(BuildUrl("location_service", "reviews", "delete", reviewId.toString()), {
            data: "{}",
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async createAddress(address: Address): Promise<Address> {
        return await gatewayAxios.post(BuildUrl("quind_user_service", "addresses", "create"), address, {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async getMainAddress(): Promise<Address> {
        return await gatewayAxios.get(BuildUrl("quind_user_service", "addresses", "main"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }
    
    async getAddresses(): Promise<Address[]> {
        return await gatewayAxios.get(BuildUrl("quind_user_service", "addresses", "get"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getOwnQrCode(): Promise<UserFileTO> {
        return await gatewayAxios.get(BuildUrl("file_service", "qrusers", "get"), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getLocationPlan(locationId: number): Promise<LocationPlan> {
        return await gatewayAxios.get(BuildUrl("payment_service", "locationplans", "find", null, "&locationId=" + locationId), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async cancelLocationPlan(locationId: number): Promise<LocationPlan> {
        return await gatewayAxios.put(BuildUrl("payment_service", "locationplans", "cancel", null, "&locationId=" + locationId), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async getTableByCode(code: string): Promise<Table> {
        return await gatewayAxios.get(BuildUrl("location_service", "table", "FindByCode", code), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    async lookUpTable(tableQRCode: string): Promise<TableQrCode> {
        return await gatewayAxios.get(BuildUrl("location_service", "table", "GetCode", null, `&id=${tableQRCode}`), {
            cancelToken: RequestManager._cancelToken.token,
        });
    }

    cancel() {
        RequestManager._cancelToken.cancel();
        RequestManager._cancelToken = axios.CancelToken.source();
    }
}