//import { ReactComponent as FlagDE } from "../assets/languages/DE.svg";

type FlagType = {
    countryCode: string;
    width: number | string;
    height: number | string;
}

const Flag = (props: FlagType) => {

    return (
        <img
            alt={"Country " + props.countryCode}
            src={require(`../assets/languages/Flag${props.countryCode}.png`)}
            width={props.width}
            height={props.height}
            style={{
                borderRadius: "45px"
            }}
        />
    );
}

export default Flag;