export default class EnvironmentHandler {
    static isTestEnvironment() {
        var host = window.location.hostname;

        return host === "test.venisly.com" || host === "test.venisly.de";
    }

    static isLocalEnvironment() {
        var host = window.location.hostname;

        return host === "localhost" || host === "127.0.0.1";
    }

    static isProductionEnvironment() {
        var host = window.location.hostname;

        return host === "venisly.com" || host === "venisly.de";
    }
}