import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactElement, ReactNode } from 'react';
import { Dialog, DialogContent, DialogTitle, Paper, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

type TransitionsDialogType = {
    open: boolean,
    onClose: () => void,
    disableBackdropClick?: boolean,
    children: ReactElement,
    title: string | ReactElement,
    ariaDescribedBy: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionsDialog = ({open, onClose, disableBackdropClick = false, title, ...props}: TransitionsDialogType) => {

    const onCloseInternal = (event: any, reason: any) => {
      if(disableBackdropClick && reason && reason == "backdropClick") {
        return;
      } else {
        onClose();
      }
    }

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onCloseInternal}
        aria-labelledby={"dialog-title"}
        aria-describedby={props.ariaDescribedBy}
        sx={{
          '.MuiPaper-root': {
            padding: 1,
          },
        }}
      >
        <DialogTitle>
          {typeof title === "string" ? (
            <Typography 
              id="dialog-title"
              variant="h6" 
              className="font-face-metropolis"
            >
              {title}
            </Typography>
          ) : (
            title
          )}
        </DialogTitle>
        <DialogContent
          key={"dialog-content"}
        >
          {props.children}
        </DialogContent>
      </Dialog>
    );
}

export default TransitionsDialog;